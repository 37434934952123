exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bldc-js": () => import("./../../../src/pages/bldc.js" /* webpackChunkName: "component---src-pages-bldc-js" */),
  "component---src-pages-blogs-beyond-induction-and-servo-js": () => import("./../../../src/pages/blogs/beyond-induction-and-servo.js" /* webpackChunkName: "component---src-pages-blogs-beyond-induction-and-servo-js" */),
  "component---src-pages-blogs-bldc-made-simple-js": () => import("./../../../src/pages/blogs/bldc-made-simple.js" /* webpackChunkName: "component---src-pages-blogs-bldc-made-simple-js" */),
  "component---src-pages-blogs-brushed-vs-brushless-dc-motors-js": () => import("./../../../src/pages/blogs/brushed-vs-brushless-dc-motors.js" /* webpackChunkName: "component---src-pages-blogs-brushed-vs-brushless-dc-motors-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-blogs-precise-syncronization-js": () => import("./../../../src/pages/blogs/precise-syncronization.js" /* webpackChunkName: "component---src-pages-blogs-precise-syncronization-js" */),
  "component---src-pages-blogs-sensor-vs-sensorless-bldc-js": () => import("./../../../src/pages/blogs/sensor-vs-sensorless-bldc.js" /* webpackChunkName: "component---src-pages-blogs-sensor-vs-sensorless-bldc-js" */),
  "component---src-pages-blogs-superiority-of-bldc-for-pump-js": () => import("./../../../src/pages/blogs/superiority-of-bldc-for-pump.js" /* webpackChunkName: "component---src-pages-blogs-superiority-of-bldc-for-pump-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manufacturing-js": () => import("./../../../src/pages/manufacturing.js" /* webpackChunkName: "component---src-pages-manufacturing-js" */),
  "component---src-pages-products-aec-40-js": () => import("./../../../src/pages/products/aec40.js" /* webpackChunkName: "component---src-pages-products-aec-40-js" */),
  "component---src-pages-products-al-57-bl-01-js": () => import("./../../../src/pages/products/al57bl01.js" /* webpackChunkName: "component---src-pages-products-al-57-bl-01-js" */),
  "component---src-pages-products-al-57-bl-02-js": () => import("./../../../src/pages/products/al57bl02.js" /* webpackChunkName: "component---src-pages-products-al-57-bl-02-js" */),
  "component---src-pages-products-al-57-bl-03-js": () => import("./../../../src/pages/products/al57bl03.js" /* webpackChunkName: "component---src-pages-products-al-57-bl-03-js" */),
  "component---src-pages-products-al-57-bl-04-js": () => import("./../../../src/pages/products/al57bl04.js" /* webpackChunkName: "component---src-pages-products-al-57-bl-04-js" */),
  "component---src-pages-products-drv-bl-120-js": () => import("./../../../src/pages/products/drv-bl120.js" /* webpackChunkName: "component---src-pages-products-drv-bl-120-js" */),
  "component---src-pages-products-drv-bl-300-js": () => import("./../../../src/pages/products/drv-bl300.js" /* webpackChunkName: "component---src-pages-products-drv-bl-300-js" */),
  "component---src-pages-products-iec-induction-js": () => import("./../../../src/pages/products/iec-induction.js" /* webpackChunkName: "component---src-pages-products-iec-induction-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-worm-gearbox-js": () => import("./../../../src/pages/products/worm-gearbox.js" /* webpackChunkName: "component---src-pages-products-worm-gearbox-js" */)
}

